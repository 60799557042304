<template>
  <div id="academicCircle">
    <div class="no-results" v-show="total === 0 && !spinShow">
      <img :src="require('@/assets/qietu/article.png')" alt="">
      <p>暂无关注</p>
    </div>
    <Row class="list" :gutter="16">
      <Col :md="24" :lg="24" :xl="12" :xxl="8" v-for="(item, i) in followList" :key="i">
        <div class="box">
          <div class="top">
            <span>{{ item.title }}</span>
            <Button type="dashed" @click="unfocus(item.circleId)" v-throttleClick>已关注</Button>
          </div>
          <div class="num">
            {{ item.scholarNum }}位学者关注，{{ item.postNum }}个帖子
          </div>
          <div class="content">
            {{ item.detail }}
          </div>
        </div>
      </Col>
    </Row>
    <Paging
      :total="total"
      :curPage="page"
      :limit="15"
      :showPage="5"
      @changePager="handlePager"
    ></Paging>
    <Spin size="large" fix v-show="spinShow"></Spin>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Paging from "@/components/paging/index.vue";
export default {
  components: {
    Paging,
  },
  name: "academicCircle",
  data() {
    return {
      followList: [],
      page: 1,
      total: 0,
      spinShow: false
    };
  },
  created() {
    this.getFollowCircleList({
      pageNum: 1,
      pageSize: 15,
    });
  },
  computed: {
    ...mapState(["hasLogin"]),
  },
  methods: {
    async unfocus(id) {
      if (this.hasLogin) {
        // 1 关注 0 取消关注
        let data = {
          attentionId: id,
          type: 0
        }
        const resp = await this.$apis.circleServe.onFocus(data);
        if (resp.resultDesc === '成功!') {
          this.getFollowCircleList({
            pageNum: this.page,
            pageSize: 15,
          })
          this.$Message.success('取消关注成功');
        } else {
          this.$Message.error("关注失败");
        }
      } else {
        this.$store.commit("showAlert", true);
      }
    },
    handlePager(i) {
      this.getFollowCircleList({
        pageNum: i,
        pageSize: 15,
      })
    },
    async getFollowCircleList(data) {
      this.spinShow = true
      const resp = await this.$apis.userServe.getFollowCircleList(data);
      this.followList = resp.data.list;
      this.page = +resp.data.pageNum
      this.total = +resp.data.total
      this.spinShow = false
    },
  },
};
</script>

<style lang="scss" scoped>
#academicCircle {
  position: relative;
  margin-top: 35px;
  p {
    font-size: 20px;
    text-align: center;
  }
  .list {
    .box {
      min-height: 176px;
      margin-bottom: 32px;
      padding: 24px;
      background: #ffffff;
      border: 1px solid #e9e9e9;
      border-radius: 16px;
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          width:300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 26px;
          color: #333333;
        }
        .ivu-btn {
          width: 82px;
          height: 34px;
          border: 1px dashed #00a4ff;
          border-radius: 10px;
        }
      }
      .num {
        font-size: 14px;
        color: #999999;
      }
      .content {
        margin-top: 24px;
        font-size: 14px;
        color: #444444;
        overflow: hidden;
        display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示 。
        text-overflow: ellipsis; // 可以用来多行文本的情况下，用省略号“...”隐藏超出范围的文本 。
        -webkit-box-orient: vertical; // 设置或检索伸缩盒对象的子元素的排列方式 。
        -webkit-line-clamp: 2;
      }
    }
  }
  .no-results {
    margin: 0 auto;
    width: 500px;
    // height: 300px;
    img {
      width: 100%;
    }
    p {
      text-align: center;
      font-size: 16px;
      color: #999999;
    }
  }
}
</style>
